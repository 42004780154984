import { render, staticRenderFns } from "./CK.vue?vue&type=template&id=72ee0f08&scoped=true&"
import script from "./CK.vue?vue&type=script&lang=js&"
export * from "./CK.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "72ee0f08",
  null
  
)

export default component.exports