<template>
  <div>
    <div class="searchList" style="position: relative; z-index: 100;">
      <a-form layout="inline">
        <a-form-item label="设备名称">
          <a-input v-model="searchParams.name" placeholder="请输入设备名称"/>
        </a-form-item>
        <a-form-item label="设备编号">
          <a-input v-model="searchParams.no" placeholder="请输入设备编号"/>
        </a-form-item>
        <a-form-item label="设备存放仓库">
          <a-select :allowClear="true" v-model="searchParams.depotId" style="width: 200px;" placeholder="请选择仓库">
            <a-select-option v-for="item in depotsList" :key="item.id" :value="item.id">{{
              item.name
              }}
            </a-select-option>
          </a-select>
        </a-form-item>
        <a-form-item class="f-r m-r-none">
          <a-button
            type="primary"
            @click="handleAdd()"
            style="margin-right: 5px;"
            v-auth="auth_equipment2.INVENTORY_CREATE_AND_UPDATE"
            icon="plus"
          >入库
          </a-button
          >
          <a-button type="primary" @click="search" icon="search">查询</a-button>
          <a-button class="m-l-6" @click="customResetForm" icon="reload">重置</a-button>
        </a-form-item>
      </a-form>
    </div>
    <div style="padding: 10px;">
      <a-table
        size="middle"
        bordered
        :rowKey="(record) => record.id"
        :columns="columns"
        @change="tableChange"
        :customRow="clickLookEquipmentInfo"
        :pagination="pagination"
        :dataSource="tableData"
        :loading="tableLoading"
        :scroll="{ x: 1400 }"
      >
        <template slot="_index" slot-scope="text, record, index">
          {{ searchParams.number * searchParams.size + index + 1 }}
        </template>
        <template slot="deviceCondition" slot-scope="deviceCondition">
          <a-tag color="#1890ff" v-if="deviceCondition === 'WELL'">良好</a-tag>
          <a-tag color="#ff0000" v-if="deviceCondition === 'DAMAGE'">损坏</a-tag>
        </template>
        <template slot="type" slot-scope="type">
          {{ type === 'ADD' ? '新增入库' : '在线设备入库' }}
        </template>
        <template slot="transferType" slot-scope="transferType">
          {{
          transferType === 'RK' ? '入库' : transferType === 'CK' ? '出库' : transferType === 'ZY' ? '转移' : '调拨'
          }}
        </template>
        <span slot="action" slot-scope="text, record">
          <a @click="clickExtendInfo(record)" @click.stop>扩展信息</a>
          <a-divider type="vertical"/>
          <a @click="handleEdit(record.id)" v-auth="auth_equipment2.INVENTORY_CREATE_AND_UPDATE" @click.stop>编辑</a>
          <a-divider type="vertical"/>
          <a href="javascript:;" @click="clickOutDepoot(record)" v-auth="auth_equipment2.INVENTORY_CK" @click.stop
          >出库</a
          >
          <a-divider type="vertical"/>
          <a href="javascript:;" @click="clickDB(record)" v-auth="auth_equipment2.INVENTORY_DB" @click.stop> 调拨</a>
          <a-divider type="vertical"/>
          <a href="javascript:;" @click="handleDelete(record.id)" v-auth="auth_equipment2.INVENTORY_DELETE" @click.stop>
            删除</a
          >
        </span>
      </a-table>
    </div>
    <a-modal title="设备入库" :width="850" :destroyOnClose="true" v-model="addModal">
      <put-form
        ref="addRef"
        @addSuccess="addSuccess"
        @addError="addError"
        :categoryId="categoryId"
        menuType='kc'
      ></put-form>
      <template slot="footer">
        <a-button :loading="addLoading" @click="handleAddOkWithContinue" type="primary" style="margin-right: 5px;"
        >保存新增
        </a-button
        >
        <a-button :loading="addLoading" @click="handleAddOkWithCopy" type="primary" style="margin-right: 5px;"
        >保存复制
        </a-button
        >
        <a-button :loading="addLoading" @click="handleAddOk" type="primary" style="margin-right: 5px;"
        >保存关闭
        </a-button
        >
        <a-button @click="closeAddModal">取消</a-button>
      </template>
    </a-modal>
    <a-modal :width="850" title="编辑" :destroyOnClose="true" :visible="editModal" @ok="handleEditOk" @cancel="closeEditModal">
      <put-form
        ref="editRef"
        type="edit"
        @editSuccess="editSuccess"
        @editError="editError"
        :categoryId="categoryId"
        menuType='kc'
      ></put-form>
    </a-modal>
    <a-modal :title="modalTitle + '_出库'" @ok="outDeportFormOk" @cancel="handleCancel" :visible="outDeportModal">
      <ck ref="ckRef" @addErr="addErr" @CKSucceed="CKSucceed"></ck>
    </a-modal>
    <a-modal :title="modalTitle + '_调拨'" @ok="DBFormOk" @cancel="handleCancel" :visible="DBModal">
      <db ref="DBRef" @DBSucceed="DBSucceed"></db>
    </a-modal>
    <a-modal :title="modalTitle + '_扩展列表'" :footer="null" @cancel="closeExtendListModal" :visible="extendInfoModal">
      <ExtendInfo ref="extendInfoRef" :systemModule="systemModule" :instanceId="instanceId" type="add"></ExtendInfo>
    </a-modal>
    <look ref="lookEquipment"></look>
  </div>
</template>
<script>
  import { repertoryColumns } from './common/common'
  import SERVICE_URLS from '@/api/service.url'
  import entityCRUDWithCopy from '@/views/common/mixins/entityCRUDWithCopy'
  import putForm from './Form'
  import ck from './operation/CK'
  import db from './operation/DB'
  import look from '../Look'
  import ExtendInfo from '@/components/ExtendInfo/ExtendInfoList'

  export default {
    components: { look, putForm, ck, db, ExtendInfo },
    mixins: [entityCRUDWithCopy],
    props: {
      categoryId: {
        type: [String, Number]
      },
      departmentId: {
        type: [String, Number]
      },
      orgRadioType: {
        type: String
      },
      sup_this: {
        type: Object
      }
    },
    data () {
      return {
        initColumns: repertoryColumns(),
        entityBaseUrl: SERVICE_URLS.equipment2.equipmentInfoApi,
        depotsList: [],
        searchParams: {
          direction: '',
          order: '',
          number: '0',
          size: '10',
          depotId: undefined,
          categoryId: this.categoryId,
          type: 'kc',
          name: '',
          no: '',
          departmentId: this.departmentId,
          orgRadioType: this.orgRadioType
        },
        addModal: false,
        editModal: false,
        outDeportModal: false,
        DBModal: false,
        systemModule: 'device_info',
        instanceId: null,
        extendInfoModal: false,
        modalTitle: ''
      }
    },
    watch: {
      categoryId: function (newVal) {
        this.searchParams.categoryId = newVal
        this.search()
      },
      departmentId: function (newVal) {
        this.searchParams.departmentId = newVal
        this.loadCheckDepotsList()
        this.search()
      },
      orgRadioType: function (newVal) {
        this.searchParams.orgRadioType = newVal
        this.search()
      }
    },
    created () {
      this.loadCheckDepotsList()
    },
    methods: {
      loadCheckDepotsList () {
        this.$http(this, {
          url: SERVICE_URLS.equipment2.equipmentInfoApi.selectDepots,
          noTips: true,
          data: {
            departmentId: this.departmentId
          },
          success: (data) => {
            this.depotsList = data.body
          }
        })
      },
      clickLookEquipmentInfo (record) {
        return {
          on: {
            click: () => {
              this.$refs.lookEquipment.visible = true
              this.$nextTick(() => {
                this.$refs.lookEquipment.getEquipmentInfo(record.id, 'isKC')
              })
            }
          }
        }
      },
      handleCancel () {
        this.outDeportModal = false
        this.DBModal = false
      },
      clickOutDepoot (record) {
        this.modalTitle = record.name
        this.outDeportModal = true
        this.$nextTick(function () {
          this.$refs.ckRef.getEquipmentInfo(record.id)
        })
      },
      outDeportFormOk () {
        this.$refs.ckRef.handleAddSubmit()
        // this.outDeportModal = false
      },
      addErr () {
        this.outDeportModal = true
      },
      clickDB (record) {
        this.modalTitle = record.name
        this.DBModal = true
        this.$nextTick(function () {
          this.$refs.DBRef.getEquipmentInfo(record.id)
        })
      },
      DBFormOk () {
        this.$refs.DBRef.handleAddSubmit()
      },
      customResetForm () {
        this.searchParams = {
          depotId: '',
          categoryId: this.categoryId,
          number: 0,
          size: 10,
          type: 'kc',
          name: '',
          no: '',
          departmentId: this.departmentId,
          orgRadioType: this.orgRadioType
        }
        this.search()
        this.loadCheckDepotsList()
      },
      closeExtendListModal () {
        this.extendInfoModal = false
      },
      clickExtendInfo (record) {
        this.modalTitle = record.name
        this.extendInfoModal = true
        this.instanceId = record.id
        this.$nextTick(() => {
          this.$refs.extendInfoRef.search()
        })
      },
      addSuccess (addType) {
        if (addType === 'copy' || addType === 'continue') {
          // 表示不关闭Modal
          this.addLoading = false
        } else {
          // 直接关闭Modal
          this.addLoading = false
          this.addModal = false
        }
        this.$message.success('保存成功')
        this.search()
        this.sup_this.$refs.statistics.loadStatistics()
      },
      handleAdd () {
        this.addModal = true
        this.$nextTick(() => {
          this.$refs.addRef.loadFunction()
        })
      },
      CKSucceed () {
        this.outDeportModal = false
        this.refreshListAndStatistics()
      },
      DBSucceed () {
        this.DBModal = false
        this.search()
      },
      editSuccess () {
        this.confirmButtonStatus = false
        this.editModal = false
        this.loadTableData()
        this.refreshListAndStatistics()
      },
      deleteSucceed () {
        this.refreshListAndStatistics()
      },
      refreshListAndStatistics () {
        this.search()
        this.sup_this.$refs.statistics.loadStatistics()
      }
    }
  }
</script>
