import { render, staticRenderFns } from "./InventoryList.vue?vue&type=template&id=0f73c3b6&scoped=true&"
import script from "./InventoryList.vue?vue&type=script&lang=js&"
export * from "./InventoryList.vue?vue&type=script&lang=js&"
import style0 from "./InventoryList.vue?vue&type=style&index=0&id=0f73c3b6&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "0f73c3b6",
  null
  
)

export default component.exports